import isNumber from 'lodash/isNumber';
import isObject from 'lodash/isObject';
import every from 'lodash/every';

export default {
  props: {
    height: {
      required: false,
      default: 1,
      validator(prop) {
        if (isNumber(prop)) {
          return true;
        }

        if (!isObject(prop)) {
          return false;
        }

        const validProps = ['xs', 'sm', 'md', 'lg'];

        /* eslint-disable max-len */
        return every(
          prop,
          (value, breakpoint) =>
            validProps.includes(breakpoint) && isNumber(value)
        );
      },
    },
  },
};
